import React from 'react';
import { graphql } from 'gatsby';

// Context
import { SwiperStateProvider } from '../context/SwiperState';

// Import Views
import { SlideView } from '../components/SlideView';
import { TextView } from '../components/TextView';

import { Head } from '../components/Head';
import { Layout } from '../components/Layout';

import { buildOpenGraphImages } from '../helpers/openGraph';

const GeneralTemplate = ({ data }) => {
  // eslint-disable-next-line no-console
  // console.log('Page Data: ', data);
  // Data
  const {
    title,
    description,
    content,
    slug,
    keywords,
    node_locale: locale,
    backgroundImage,
    sharingImage,
  } = data.contentfulPage;

  const { author } = data.site.siteMetadata;

  // Bools
  const isHome = slug === 'home';
  // eslint-disable-next-line dot-notation
  const isSlidePage = content['__typename'] === 'ContentfulCategory';

  // OpenGraph
  const ogImages = buildOpenGraphImages(sharingImage);

  return (
    <>
      <Head
        locale={locale}
        title={isHome ? title : `${title} | ${author}`}
        description={(description || {}).description}
        keywords={keywords}
        openGraph={{
          type: 'website',
          title: isHome ? title : `${title} | ${author}`,
          description: (description || {}).description,
          images: ogImages,
        }}
      />
      <SwiperStateProvider>
        <Layout
          className={`bg-fade ${!isSlidePage && 'rte-text'}`}
          backgroundImage={backgroundImage}
        >
          {isSlidePage && <SlideView data={content} />}
          {!isSlidePage && <TextView data={content} />}
        </Layout>
      </SwiperStateProvider>
    </>
  );
};
export default GeneralTemplate;

export const GeneralQuery = graphql`
  query GeneralQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulPage(contentful_id: { eq: $id }) {
      __typename
      id
      node_locale
      title
      description {
        description
      }
      keywords
      slug
      backgroundColor
      backgroundImage {
        __typename
        fixed(width: 2560, toFormat: JPG, quality: 80) {
          src
        }
      }
      content {
        ... on ContentfulCategory {
          __typename
          id
          title
          slides {
            ... on ContentfulSlideImage {
              __typename
              id
              title
              subtitle {
                subtitle
              }
              backgroundColor
              imageFit
              image {
                __typename
                # fluid(quality: 75, maxWidth: 2560) {
                #   srcSetWebp
                #   base64
                #   aspectRatio
                #   sizes
                #   srcSet
                #   src
                # }
                id
                description
                file {
                  contentType
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on ContentfulSlideVideo {
              __typename
              id
              title
              subtitle {
                subtitle
              }
              backgroundColor
              vimeoId
              videoFit
              videoControls
              videoPoster {
                fixed(width: 1920, toFormat: JPG, quality: 60) {
                  src
                }
              }
            }
          }
        }
        ... on ContentfulText {
          __typename
          id
          richText {
            json
          }
        }
      }
      sharingImage {
        horizontal: fixed(
          width: 1200
          height: 630
          toFormat: JPG
          quality: 50
        ) {
          src
          width
          height
        }
        square: fixed(width: 1080, height: 1080, toFormat: JPG, quality: 50) {
          src
          width
          height
        }
      }
    }
  }
`;
