import styled from 'styled-components';

export const ArticleGrid = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

export const ArticleHeader = styled.header`
  display: flex;
  margin: 0 7.5rem;
  height: 7.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 4rem;
  }
`;

export const ArticleBody = styled.section`
  max-width: 80rem;
  margin: 0 auto;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 7rem;
  margin-bottom: 10rem;
  overflow-y: auto;
  color: ${({ theme }) => theme.color.foreground};

  span.rte-accent {
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    word-break: break-word;
  }

  h3 {
    text-transform: uppercase;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    word-break: break-word;
  }

  ul {
    list-style: disc;
    padding-left: 3rem;
    margin: 2rem 0;

    li {
      padding-left: 1rem;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  ol {
    list-style: decimal-leading-zero;
    padding-left: 3rem;
    margin: 2rem 0;

    li {
      padding-left: 1rem;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  a {
    color: ${({ theme }) => theme.color.foreground};
    will-change: opacity;
    opacity: 1;
    position: relative;
    transition: opacity 0.4s ${({ theme }) => theme.easings.animatedBorder};
    word-break: break-all;

    &::after {
      border-bottom: 1px solid ${({ theme }) => theme.color.accentMid};
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;

      will-change: transform;
      transform: scaleX(1);
      transform-origin: left;
      transition: transform 0.4s ${({ theme }) => theme.easings.animatedBorder};
    }
    &:hover {
      will-change: opacity;
      opacity: 0.5;
      transition: opacity 0.4s ${({ theme }) => theme.easings.animatedBorder};

      &::after {
        will-change: transform;
        transform-origin: right;
        transform: scaleX(0);
      }
    }
  }
`;

export const RichTextParagraph = styled.p`
  line-height: 1.8;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  letter-spacing: 0.2px;
  word-break: initial;
`;
