import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { Menu } from '../Menu';
import {
  ArticleBody,
  ArticleGrid,
  ArticleHeader,
  RichTextParagraph,
} from './styles';

const Bold = ({ children }) => <span className="rte-accent">{children}</span>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <RichTextParagraph>{children}</RichTextParagraph>
    ),
  },
  renderText: (text) =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
};

const TextView = ({ data }) => (
  <>
    <ArticleGrid>
      <ArticleHeader>
        <Menu />
      </ArticleHeader>
      <ArticleBody>
        {documentToReactComponents(data.richText.json, options)}
      </ArticleBody>
    </ArticleGrid>
  </>
);

export default TextView;
